import React from 'react'
import { observer } from 'mobx-react'
import { Button, LinearProgress, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Paper } from '@material-ui/core'
import ApiClient, { apiRoute, ApiRoutes } from '../api/ApiClient'
import { computed, observable } from 'mobx'
import Category from '../models/Category'
import { extractErrorMessage, modelToCamelCase } from '../common/Util'
import { Alert, AlertTitle } from '@material-ui/lab'
import AdapterLink from '../components/AdapterLink'
import { route } from '../routes/routes'
import { Routes } from '../routes/AppRoutes'
import { Redirect } from 'react-router'
import AuthStore from '../stores/AuthStore'
import { Link } from 'react-router-dom'

@observer
export default class HomeView extends React.Component {
  @observable private loading = false
  @observable private categories: Category[] = []
  @observable private error?: string
  @observable private tipTarget?: Category

  @computed get invitingMemberProfileImageUrl () {
    return AuthStore.getAuthUser()!.invitingMember.profileImageUrl
  }

  @computed get invitingMemberCategory () {
    return this.categories.find(c => c.member.id === AuthStore.getAuthUser()!.invitingMember.memberId)
  }

  @computed get shouldShowIntroVideo () {
    return false
    // return localStorage.getItem('hasSeenIntroVideo') !== '1'
  }

  componentDidMount (): void {
    this.loading = true
    ApiClient.getInstance().get(apiRoute(ApiRoutes.categories))
      .then(response => {
        this.categories = response.data.categories.map(c => new Category().init(modelToCamelCase(c)))
      })
      .catch(error => {
        this.error = extractErrorMessage(error)
      })
      .then(() => {
        this.loading = false
      })

    window.scrollTo(0, 0)
  }

  render () {
    return this.shouldShowIntroVideo
      ? <Redirect to={route(Routes.introVideo)}/>
      : <>
        <Paper>
          {
            this.loading
              ? <LinearProgress/>
              : this.error
              ? <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {this.error}
              </Alert>
              : <>
                <div className="categories-header">
                  Below you will find my trusted referrals. When you need their services, simply send a message here and I'll have them contact you.
                  {
                    this.invitingMemberProfileImageUrl
                      ? <div className="header-profile-image">
                        {
                          this.invitingMemberCategory
                            ? <Link to={route(Routes.category, { id: this.invitingMemberCategory.id })}><img src={this.invitingMemberProfileImageUrl}/></Link>
                            : <img src={this.invitingMemberProfileImageUrl}/>
                        }
                      </div>
                      : null
                  }
                </div>
                <List className="category-list">
                  {this.categories.map(category => <ListItem
                    button
                    key={category.id}
                    alignItems="center"
                    component={AdapterLink}
                    to={route(Routes.category, { id: category.id })}
                  >
                    <ListItemIcon style={{ width: 70, overflow: 'hidden', fontSize: 10 }}>
                      <img alt={category.member.fullName} src={category.member.profileImageUrl || category.logoUrl || '/img/user-profile-placeholder.png'} style={{ width: 60, height: 60, objectFit: 'cover', objectPosition: 'top center', backgroundColor: '#ccc' }}/>
                    </ListItemIcon>
                    <ListItemText
                      primary={category.name}
                    />
                    <ListItemSecondaryAction>
                      <Button
                        variant="contained"
                        color="primary"
                        component={AdapterLink}
                        to={route(Routes.category, { id: category.id })}
                        style={{ textAlign: 'center', lineHeight: 1.4 }}
                        size="small"
                      >Contact<br/>Me</Button>
                    </ListItemSecondaryAction>
                  </ListItem>)}
                </List>
              </>
          }
        </Paper>
      </>
  }
}
